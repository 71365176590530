
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { FileTypeDetail } from '@/types/product'
@Component
@KeepAlive
export default class FileTypeList extends Vue {
  private loading = false
  private info: Array<FileTypeDetail> = []

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.product.selectProductFileTypeByPage).then((res) => {
      this.info = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd () {
    this.$router.push({
      name: 'productFileTypeAdd'
    })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'productFileTypeEdit',
      params: { id }
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该文件类型吗？', '提示').then(() => {
      this.$axios.post(this.$apis.product.deleteProductFileType, {
        typeId: id
      }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    })
  }
}
